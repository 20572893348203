import { SVGProps, useId } from 'react';

export const Staking = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 1 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7015 0.254123C14.2862 0.422561 13.897 0.675219 13.5601 1.0121L7.32377 7.24841C5.95694 8.61525 5.95694 10.8313 7.32377 12.1982L9.72866 14.6031L16 8.33174L22.2713 14.6031L24.6762 12.1982C26.043 10.8313 26.043 8.61525 24.6762 7.24841L18.4399 1.0121C17.4292 0.00146473 15.9476 -0.251193 14.7015 0.254123ZM7.32473 24.7515L13.5611 30.9878C13.8979 31.3247 14.2871 31.5773 14.7025 31.7458C15.9486 32.2511 17.4302 31.9984 18.4408 30.9878L24.6771 24.7515C26.044 23.3846 26.044 21.1685 24.6771 19.8017L22.2722 17.3968L16.0009 23.6681L9.72962 17.3968L7.32473 19.8017C5.95789 21.1685 5.95789 23.3846 7.32473 24.7515ZM13.5605 18.4395C12.2131 17.0922 12.2131 14.9077 13.5605 13.5604C14.9078 12.2131 17.0922 12.2131 18.4395 13.5604C19.7869 14.9077 19.7869 17.0922 18.4395 18.4395C17.0922 19.7868 14.9078 19.7868 13.5605 18.4395ZM1.01183 18.4395C-0.335488 17.0922 -0.335488 14.9078 1.01183 13.5604C2.35916 12.2131 4.5436 12.2131 5.89092 13.5604C7.23824 14.9078 7.23824 17.0922 5.89092 18.4395C4.5436 19.7868 2.35916 19.7868 1.01183 18.4395ZM26.1091 18.4395C24.7618 17.0922 24.7618 14.9078 26.1091 13.5604C27.4564 12.2131 29.6409 12.2131 30.9882 13.5604C32.3355 14.9078 32.3355 17.0922 30.9882 18.4395C29.6409 19.7868 27.4564 19.7868 26.1091 18.4395Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="24"
          y1="-1.3926e-07"
          x2="17.707"
          y2="32.3322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
