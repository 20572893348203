import { SVGProps, useId } from 'react';

export const Dao = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 4 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M13.56 1.01072C14.907 -0.336905 17.0927 -0.336907 18.4397 1.01072L22.9719 5.54237L15.9999 12.5141L15.6513 12.1655C13.5331 10.0478 10.0995 10.0478 7.98224 12.1655C5.86494 14.2833 5.86494 17.7168 7.98224 19.8345L12.1658 24.0175C14.2831 26.1352 17.7166 26.1352 19.8339 24.0175L30.6404 13.2109L30.9895 13.5599C32.3365 14.9075 32.3365 17.0925 30.9895 18.4401L18.4397 30.9893C17.0927 32.3369 14.907 32.3369 13.56 30.9893L1.01027 18.4401C-0.336756 17.0925 -0.336756 14.9075 1.01027 13.5599L13.56 1.01072Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M9.37644 13.5599C10.7244 12.2122 12.9091 12.2122 14.2571 13.5599L15.9999 15.3028L24.7148 6.5881C26.0618 5.24048 28.2475 5.24048 29.5945 6.5881C30.9425 7.93573 30.9425 10.1207 29.5945 11.4683L18.4397 22.6232C17.0927 23.9708 14.907 23.9708 13.56 22.6232L9.37644 18.4401C8.02942 17.0925 8.02942 14.9075 9.37644 13.5599Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="23.9998"
          y1="4.5597e-08"
          x2="15.736"
          y2="31.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="23.9998"
          y1="4.5597e-08"
          x2="15.736"
          y2="31.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
