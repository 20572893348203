import { SVGProps, useId } from 'react';

export const MagicSqaureNewLogo = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 5 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 211 32" {...props}>
      <path
        d="M203.289 24.3127C201.894 24.3127 200.648 24.0193 199.551 23.4327C198.453 22.8277 197.588 22.0027 196.956 20.9577C196.323 19.9127 196.007 18.7302 196.007 17.4102C196.007 16.0902 196.314 14.9077 196.928 13.8627C197.56 12.8177 198.407 11.9927 199.467 11.3877C200.546 10.7827 201.745 10.4802 203.066 10.4802C204.35 10.4802 205.484 10.7643 206.47 11.3327C207.456 11.901 208.228 12.6893 208.786 13.6977C209.362 14.6877 209.651 15.8243 209.651 17.1077C209.651 17.3277 209.632 17.566 209.595 17.8227C209.576 18.061 209.53 18.336 209.455 18.6477H198.184V16.0077L207.781 15.9802L206.33 17.0802C206.312 16.2918 206.172 15.6318 205.912 15.1002C205.652 14.5685 205.28 14.1652 204.796 13.8902C204.331 13.5968 203.745 13.4502 203.038 13.4502C202.313 13.4502 201.68 13.6152 201.141 13.9452C200.602 14.2568 200.183 14.706 199.885 15.2927C199.588 15.861 199.439 16.5485 199.439 17.3552C199.439 18.1618 199.597 18.8677 199.913 19.4727C200.23 20.0593 200.676 20.5177 201.253 20.8477C201.829 21.1593 202.499 21.3152 203.261 21.3152C203.95 21.3152 204.573 21.2052 205.131 20.9852C205.707 20.7468 206.2 20.3985 206.609 19.9402L208.73 22.0302C208.06 22.7818 207.251 23.3502 206.303 23.7352C205.373 24.1202 204.368 24.3127 203.289 24.3127Z"
        fill="currentColor"
      />
      <path
        d="M185.953 24.0377V10.7552H189.524V24.0377H185.953ZM189.524 16.6952L188.157 15.8152C188.306 14.1835 188.771 12.891 189.552 11.9377C190.352 10.966 191.505 10.4802 193.012 10.4802C193.681 10.4802 194.277 10.5902 194.797 10.8102C195.318 11.0302 195.792 11.3785 196.22 11.8552L193.988 14.3852C193.765 14.1468 193.505 13.9727 193.207 13.8627C192.928 13.7527 192.602 13.6977 192.23 13.6977C191.431 13.6977 190.78 13.9452 190.277 14.4402C189.775 14.9168 189.524 15.6685 189.524 16.6952Z"
        fill="currentColor"
      />
      <path
        d="M175.52 24.3127C174.292 24.3127 173.185 24.0102 172.2 23.4052C171.232 22.8002 170.461 21.9843 169.884 20.9577C169.326 19.9127 169.047 18.7393 169.047 17.4377C169.047 16.1177 169.326 14.9352 169.884 13.8902C170.461 12.8452 171.232 12.0202 172.2 11.4152C173.185 10.7918 174.292 10.4802 175.52 10.4802C176.487 10.4802 177.352 10.6818 178.115 11.0852C178.896 11.4885 179.519 12.0385 179.984 12.7352C180.449 13.4135 180.691 14.1927 180.709 15.0727V19.7202C180.691 20.6002 180.449 21.3885 179.984 22.0852C179.537 22.7818 178.924 23.3318 178.142 23.7352C177.361 24.1202 176.487 24.3127 175.52 24.3127ZM176.162 21.0677C177.203 21.0677 178.04 20.7285 178.673 20.0502C179.324 19.3535 179.649 18.4735 179.649 17.4102C179.649 16.6952 179.5 16.0627 179.203 15.5127C178.924 14.9627 178.524 14.5318 178.003 14.2202C177.482 13.9085 176.878 13.7527 176.189 13.7527C175.501 13.7527 174.897 13.9085 174.376 14.2202C173.855 14.5318 173.446 14.9627 173.148 15.5127C172.851 16.0627 172.702 16.6952 172.702 17.4102C172.702 18.1252 172.851 18.7577 173.148 19.3077C173.446 19.8577 173.855 20.2885 174.376 20.6002C174.897 20.9118 175.492 21.0677 176.162 21.0677ZM179.454 24.0377V20.4627L180.04 17.2452L179.454 14.0277V10.7552H182.997V24.0377H179.454Z"
        fill="currentColor"
      />
      <path
        d="M160.918 24.3127C159.746 24.3127 158.695 24.0652 157.765 23.5702C156.853 23.0752 156.137 22.3877 155.616 21.5077C155.114 20.6277 154.863 19.6102 154.863 18.4552V10.7552H158.434V18.3727C158.434 18.941 158.527 19.4268 158.713 19.8302C158.918 20.2152 159.206 20.5177 159.578 20.7377C159.95 20.9577 160.397 21.0677 160.918 21.0677C161.699 21.0677 162.303 20.8385 162.731 20.3802C163.159 19.9035 163.373 19.2343 163.373 18.3727V10.7552H166.972V18.4827C166.972 19.6377 166.712 20.6552 166.191 21.5352C165.689 22.4152 164.982 23.1027 164.07 23.5977C163.159 24.0743 162.108 24.3127 160.918 24.3127Z"
        fill="currentColor"
      />
      <path
        d="M144.486 24.3127C143.258 24.3127 142.152 24.0102 141.166 23.4052C140.199 22.8002 139.427 21.9843 138.85 20.9577C138.292 19.9127 138.013 18.7393 138.013 17.4377C138.013 16.1177 138.292 14.9352 138.85 13.8902C139.427 12.8452 140.199 12.0202 141.166 11.4152C142.152 10.7918 143.258 10.4802 144.486 10.4802C145.453 10.4802 146.318 10.6818 147.081 11.0852C147.862 11.4885 148.485 12.0385 148.95 12.7352C149.415 13.4135 149.657 14.1927 149.675 15.0727V19.7202C149.657 20.6002 149.415 21.3885 148.95 22.0852C148.504 22.7818 147.89 23.3318 147.109 23.7352C146.327 24.1202 145.453 24.3127 144.486 24.3127ZM145.128 21.0677C146.169 21.0677 147.006 20.7285 147.639 20.0502C148.29 19.3535 148.615 18.4735 148.615 17.4102C148.615 16.6952 148.466 16.0627 148.169 15.5127C147.89 14.9627 147.49 14.5318 146.969 14.2202C146.448 13.9085 145.844 13.7527 145.156 13.7527C144.467 13.7527 143.863 13.9085 143.342 14.2202C142.821 14.5318 142.412 14.9627 142.114 15.5127C141.817 16.0627 141.668 16.6952 141.668 17.4102C141.668 18.1252 141.817 18.7577 142.114 19.3077C142.412 19.8577 142.821 20.2885 143.342 20.6002C143.863 20.9118 144.458 21.0677 145.128 21.0677ZM148.42 29.5927V20.7102L149.006 17.4927L148.42 14.2752V10.7552H151.963V29.5927H148.42Z"
        fill="currentColor"
      />
      <path
        d="M129.21 24.3127C127.629 24.3127 126.281 24.0468 125.165 23.5152C124.049 22.9835 123.044 22.186 122.152 21.1227L124.607 18.7027C125.183 19.4543 125.844 20.041 126.588 20.4627C127.35 20.866 128.28 21.0677 129.378 21.0677C130.401 21.0677 131.201 20.8752 131.777 20.4902C132.372 20.0868 132.67 19.5368 132.67 18.8402C132.67 18.2535 132.503 17.7768 132.168 17.4102C131.833 17.0252 131.387 16.7135 130.829 16.4752C130.289 16.2185 129.685 15.9802 129.015 15.7602C128.364 15.5402 127.704 15.2927 127.034 15.0177C126.383 14.7427 125.779 14.4035 125.221 14.0002C124.681 13.5785 124.244 13.0468 123.909 12.4052C123.574 11.7635 123.407 10.9477 123.407 9.95768C123.407 8.78434 123.695 7.79434 124.272 6.98768C124.849 6.16268 125.639 5.53018 126.644 5.09018C127.648 4.65018 128.773 4.43018 130.02 4.43018C131.359 4.43018 132.558 4.67768 133.619 5.17268C134.697 5.64934 135.572 6.28184 136.241 7.07018L133.786 9.49018C133.209 8.88518 132.614 8.43601 132 8.14268C131.405 7.83101 130.726 7.67518 129.964 7.67518C129.071 7.67518 128.373 7.84934 127.871 8.19768C127.369 8.54601 127.118 9.03184 127.118 9.65518C127.118 10.1868 127.285 10.6268 127.62 10.9752C127.955 11.3052 128.392 11.5893 128.931 11.8277C129.489 12.066 130.094 12.2952 130.745 12.5152C131.415 12.7352 132.075 12.9827 132.726 13.2577C133.377 13.5327 133.972 13.8902 134.511 14.3302C135.069 14.7702 135.516 15.3385 135.851 16.0352C136.186 16.7135 136.353 17.5568 136.353 18.5652C136.353 20.3618 135.711 21.7735 134.428 22.8002C133.163 23.8085 131.424 24.3127 129.21 24.3127Z"
        fill="currentColor"
      />
      <path
        d="M110.308 24.3127C108.95 24.3127 107.722 24.0102 106.625 23.4052C105.528 22.8002 104.663 21.9752 104.03 20.9302C103.416 19.8852 103.109 18.7118 103.109 17.4102C103.109 16.0902 103.426 14.9077 104.058 13.8627C104.69 12.8177 105.546 11.9927 106.625 11.3877C107.722 10.7827 108.959 10.4802 110.336 10.4802C111.377 10.4802 112.345 10.6727 113.237 11.0577C114.13 11.4427 114.902 11.9927 115.553 12.7077L113.265 15.0177C112.893 14.596 112.456 14.2843 111.954 14.0827C111.47 13.881 110.931 13.7802 110.336 13.7802C109.629 13.7802 109.006 13.936 108.466 14.2477C107.927 14.5593 107.508 14.981 107.211 15.5127C106.913 16.0443 106.764 16.6768 106.764 17.4102C106.764 18.1068 106.913 18.7302 107.211 19.2802C107.508 19.8302 107.927 20.261 108.466 20.5727C109.006 20.866 109.629 21.0127 110.336 21.0127C110.95 21.0127 111.498 20.9118 111.982 20.7102C112.484 20.4902 112.921 20.1785 113.293 19.7752L115.609 22.0577C114.939 22.791 114.149 23.3502 113.237 23.7352C112.345 24.1202 111.368 24.3127 110.308 24.3127Z"
        fill="currentColor"
      />
      <path
        d="M97.1634 24.0377V10.7552H100.735V24.0377H97.1634ZM98.949 8.58268C98.3724 8.58268 97.8888 8.39018 97.4982 8.00518C97.1076 7.62018 96.9123 7.14351 96.9123 6.57518C96.9123 6.00684 97.1076 5.53018 97.4982 5.14518C97.8888 4.74184 98.3724 4.54018 98.949 4.54018C99.5442 4.54018 100.028 4.74184 100.4 5.14518C100.772 5.53018 100.958 6.00684 100.958 6.57518C100.958 7.14351 100.772 7.62018 100.4 8.00518C100.028 8.39018 99.5442 8.58268 98.949 8.58268Z"
        fill="currentColor"
      />
      <path
        d="M86.8932 29.8952C85.4609 29.8952 84.1961 29.6385 83.0987 29.1252C82.0199 28.6302 81.1549 27.9243 80.5039 27.0077L82.7639 24.7802C83.2847 25.4035 83.8706 25.871 84.5216 26.1827C85.1726 26.4943 85.9631 26.6502 86.8932 26.6502C88.0278 26.6502 88.9299 26.366 89.5995 25.7977C90.2691 25.2293 90.6039 24.441 90.6039 23.4327V20.1052L91.1898 17.1902L90.6318 14.2752V10.7552H94.1752V23.3777C94.1752 24.6977 93.859 25.8435 93.2266 26.8152C92.6128 27.7868 91.7571 28.5385 90.6597 29.0702C89.5809 29.6202 88.3254 29.8952 86.8932 29.8952ZM86.7537 23.7077C85.526 23.7077 84.4286 23.4235 83.4614 22.8552C82.5128 22.2685 81.7595 21.471 81.2014 20.4627C80.662 19.4543 80.3923 18.3268 80.3923 17.0802C80.3923 15.8335 80.662 14.7152 81.2014 13.7252C81.7595 12.7352 82.5128 11.9468 83.4614 11.3602C84.4286 10.7735 85.526 10.4802 86.7537 10.4802C87.7581 10.4802 88.6416 10.6818 89.4042 11.0852C90.1854 11.4702 90.7992 12.011 91.2456 12.7077C91.692 13.386 91.9245 14.1743 91.9431 15.0727V19.1152C91.9245 20.0135 91.6827 20.811 91.2177 21.5077C90.7713 22.186 90.1575 22.7268 89.3763 23.1302C88.6137 23.5152 87.7395 23.7077 86.7537 23.7077ZM87.4233 20.4627C88.1115 20.4627 88.7067 20.3252 89.2089 20.0502C89.7297 19.7568 90.1203 19.3627 90.3807 18.8677C90.6597 18.3543 90.7992 17.7585 90.7992 17.0802C90.7992 16.4202 90.6597 15.8427 90.3807 15.3477C90.1203 14.8343 89.7297 14.431 89.2089 14.1377C88.7067 13.8443 88.1208 13.6977 87.4512 13.6977C86.763 13.6977 86.1584 13.8443 85.6376 14.1377C85.1354 14.431 84.7355 14.8343 84.4379 15.3477C84.1589 15.8427 84.0194 16.4202 84.0194 17.0802C84.0194 17.7402 84.1589 18.3268 84.4379 18.8402C84.7355 19.3535 85.1354 19.7568 85.6376 20.0502C86.1584 20.3252 86.7537 20.4627 87.4233 20.4627Z"
        fill="currentColor"
      />
      <path
        d="M70.5452 24.3127C69.3176 24.3127 68.2109 24.0102 67.225 23.4052C66.2578 22.8002 65.4859 21.9843 64.9093 20.9577C64.3513 19.9127 64.0723 18.7393 64.0723 17.4377C64.0723 16.1177 64.3513 14.9352 64.9093 13.8902C65.4859 12.8452 66.2578 12.0202 67.225 11.4152C68.2109 10.7918 69.3176 10.4802 70.5452 10.4802C71.5124 10.4802 72.3773 10.6818 73.1399 11.0852C73.9212 11.4885 74.5443 12.0385 75.0093 12.7352C75.4743 13.4135 75.7161 14.1927 75.7347 15.0727V19.7202C75.7161 20.6002 75.4743 21.3885 75.0093 22.0852C74.5629 22.7818 73.9491 23.3318 73.1678 23.7352C72.3866 24.1202 71.5124 24.3127 70.5452 24.3127ZM71.1869 21.0677C72.2285 21.0677 73.0655 20.7285 73.698 20.0502C74.349 19.3535 74.6745 18.4735 74.6745 17.4102C74.6745 16.6952 74.5257 16.0627 74.2281 15.5127C73.9491 14.9627 73.5492 14.5318 73.0283 14.2202C72.5075 13.9085 71.903 13.7527 71.2148 13.7527C70.5266 13.7527 69.9221 13.9085 69.4013 14.2202C68.8805 14.5318 68.4713 14.9627 68.1737 15.5127C67.876 16.0627 67.7272 16.6952 67.7272 17.4102C67.7272 18.1252 67.876 18.7577 68.1737 19.3077C68.4713 19.8577 68.8805 20.2885 69.4013 20.6002C69.9221 20.9118 70.5173 21.0677 71.1869 21.0677ZM74.4792 24.0377V20.4627L75.0651 17.2452L74.4792 14.0277V10.7552H78.0225V24.0377H74.4792Z"
        fill="currentColor"
      />
      <path
        d="M41.9016 24.0377V4.70517H44.5243L52.5038 17.3552H50.9693L58.9488 4.70517H61.5436V24.0377H57.8607V11.0027L58.5861 11.1952L52.9781 20.1052H50.4671L44.887 11.1952L45.6124 11.0027V24.0377H41.9016Z"
        fill="currentColor"
      />

      <path
        d="M0.996501 18.3712C-0.332167 17.0616 -0.332167 14.9384 0.996501 13.6288L3.40227 11.2575L5.80804 13.6288C7.1367 14.9384 7.1367 17.0616 5.80804 18.3712C4.47937 19.6808 2.32517 19.6808 0.996501 18.3712Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M7.41188 24.6945C6.08321 23.3849 6.08321 21.2616 7.41188 19.9521L9.81765 17.5808L18.6388 26.2753C19.9675 27.5849 19.9675 29.7082 18.6388 31.0178C17.3101 32.3274 15.1559 32.3274 13.8273 31.0178L7.41188 24.6945Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M9.81765 4.93425L5.00611 9.67671L22.6484 27.0658L27.4599 22.3233L9.81765 4.93425Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        d="M13.8273 5.72466C12.4986 4.41507 12.4986 2.29179 13.8273 0.982197C15.1559 -0.327399 17.3101 -0.327399 18.6388 0.982197L25.0542 7.30548C26.3828 8.61508 26.3828 10.7383 25.0542 12.0479L22.6484 14.4192L13.8273 5.72466Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <path
        d="M26.658 13.6288C25.3293 14.9384 25.3293 17.0616 26.658 18.3712L29.0638 20.7425L31.4696 18.3712C32.7982 17.0616 32.7982 14.9384 31.4696 13.6288C30.1409 12.3192 27.9867 12.3192 26.658 13.6288Z"
        fill={`url(#${gradientIds[4]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="24.3495"
          y1="4.5597e-08"
          x2="16.1898"
          y2="31.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="24.3495"
          y1="4.5597e-08"
          x2="16.1898"
          y2="31.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="24.3495"
          y1="4.5597e-08"
          x2="16.1898"
          y2="31.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="24.3495"
          y1="4.5597e-08"
          x2="16.1898"
          y2="31.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[4]}
          x1="24.3495"
          y1="4.5597e-08"
          x2="16.1898"
          y2="31.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
