import { SVGProps, useId } from 'react';

export const Launchpad = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 3 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M13.5719 1.01003C14.9187 -0.336678 17.1022 -0.336678 18.449 1.01003L22.1769 4.73782C16.8074 6.2031 11.7068 8.91784 7.33749 12.88C6.97775 13.2062 6.41565 13.4288 5.70055 13.4596C3.78081 13.5423 1.87363 14.0723 0.149994 15.0521C0.304658 14.5022 0.598325 13.9832 1.03099 13.5506L13.5719 1.01003Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M30.9899 13.5506L27.2619 9.8227C25.7964 15.1928 23.0809 20.2941 19.1176 24.6636C18.7914 25.0232 18.5687 25.585 18.5377 26.2999C18.4546 28.2196 17.924 30.1266 16.9435 31.85C17.4949 31.6956 18.0153 31.4016 18.449 30.968L30.9899 18.4274C32.3367 17.0807 32.3367 14.8973 30.9899 13.5506Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.97031 18.3497C0.437968 17.9642 0.426867 17.1742 0.993412 16.841C2.47284 15.971 4.12221 15.5002 5.78556 15.4286C6.83893 15.3832 7.88052 15.0481 8.66154 14.3399C13.2157 10.2101 18.6071 7.51436 24.2545 6.25255C25.1584 6.05058 25.9496 6.84182 25.7477 7.74572C24.4856 13.3936 21.7891 18.7856 17.6582 23.34C16.95 24.1207 16.6148 25.1618 16.5692 26.2148C16.4972 27.8772 16.0264 29.5255 15.1568 31.004C14.8236 31.5705 14.0336 31.5594 13.6481 31.0271L12.4508 29.3737C11.9923 28.7405 11.3253 28.2991 10.5976 28.0135C9.14528 27.4436 7.78396 26.5722 6.60981 25.3994L6.60033 25.39C5.42733 24.2157 4.55587 22.8542 3.98594 21.4017C3.70034 20.6739 3.25888 20.0068 2.62559 19.5483L0.97031 18.3497ZM17.4847 11.7909C18.2664 11.3866 19.2198 11.5347 19.842 12.157C20.4645 12.7794 20.6125 13.7332 20.2078 14.515L19.5791 15.7294C18.8152 17.2051 16.8366 17.512 15.6615 16.3369C14.4861 15.1616 14.7935 13.1825 16.27 12.419L17.4847 11.7909Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="24.0375"
          y1="4.53833e-08"
          x2="15.8125"
          y2="31.7821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="24.0375"
          y1="4.53833e-08"
          x2="15.8125"
          y2="31.7821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="24.0375"
          y1="4.53833e-08"
          x2="15.8125"
          y2="31.7821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        {/* <clipPath id="clip0_1841_59902">
          <rect width="32" height="32" fill="white" />
        </clipPath> */}
      </defs>
    </svg>
  );
};
