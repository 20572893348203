import { SVGProps, useId } from 'react';

export const Boost = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 1 }, (_, i) => `${id}-${i}`);

  // fill-ule="evenodd" clipRule="evenodd"
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M14.7016 0.254123C14.2862 0.422561 13.897 0.675219 13.5601 1.0121L0.993603 13.5786C-0.353907 14.9261 -0.353906 17.1109 0.993604 18.4584C2.34111 19.8059 4.52586 19.8059 5.87337 18.4584L16 8.33174L26.1247 18.4564C27.4722 19.8039 29.657 19.8039 31.0045 18.4564C32.352 17.1089 32.352 14.9242 31.0045 13.5767L18.4399 1.0121C17.4293 0.00146473 15.9477 -0.251193 14.7016 0.254123ZM18.4049 13.5275L18.422 13.5447L18.4399 13.5624L24.7139 19.8364C26.0614 21.1839 26.0614 23.3687 24.7139 24.7162C23.3664 26.0637 21.1816 26.0637 19.8341 24.7162L16 20.8821L12.1659 24.7162C10.8184 26.0637 8.63365 26.0637 7.28614 24.7162C5.93863 23.3687 5.93864 21.1839 7.28615 19.8364L16 11.1226L18.4049 13.5275ZM13.5605 30.9883C12.2131 29.6409 12.2131 27.4565 13.5605 26.1092L16 23.6696L18.4396 26.1092C19.7869 27.4565 19.7869 29.6409 18.4396 30.9883C17.0922 32.3356 14.9078 32.3356 13.5605 30.9883Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="24"
          y1="8.89952e-08"
          x2="17.7241"
          y2="32.3346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
