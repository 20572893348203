import { SVGProps, useId } from 'react';

export const Otc = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 4 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5024 10.0854C10.7814 10.0854 8.57552 12.2913 8.57552 15.0123L27.856 15.0124C28.5034 15.0258 29.1551 14.7855 29.6491 14.2915C30.6109 13.3297 30.6109 11.7702 29.6491 10.8084L23.6746 4.8339C22.7128 3.87205 21.1533 3.87205 20.1915 4.8339C19.2296 5.79574 19.2296 7.35521 20.1915 8.31705L21.9599 10.0855L13.5024 10.0854Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5035 21.9122C21.2246 21.9122 23.4304 19.7064 23.4304 16.9854L4.42348 16.9853C3.69365 16.8926 2.93026 17.1265 2.36978 17.6869C1.88306 18.1737 1.64263 18.8134 1.6485 19.4513C1.64929 20.2265 2.00813 20.9178 2.56856 21.3689L8.34368 27.144C9.30553 28.1059 10.865 28.1059 11.8268 27.144C12.7887 26.1822 12.7887 24.6227 11.8268 23.6609L10.0782 21.9122L18.5035 21.9122Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M0.238761 17.264C0.433256 16.9158 0.678782 16.5883 0.974375 16.2926C1.94781 15.3191 3.26306 14.8939 4.52921 15.0134L6.60335 15.0134C6.60335 11.2022 9.6912 8.11351 13.5022 8.11353L17.7712 8.11354C17.1906 6.54003 17.5325 4.70331 18.7967 3.43954C19.1597 3.07658 19.5698 2.78968 20.0079 2.57885L18.4395 1.01073C17.0924 -0.33691 14.9068 -0.33691 13.5597 1.01073L1.01 13.5599C0.00864005 14.5613 -0.247477 16.025 0.238761 17.264Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        d="M31.7633 14.7424C31.5708 15.08 31.331 15.3978 31.0431 15.6859C30.1592 16.5692 28.9923 17.0022 27.8378 16.9843L25.4016 16.9842C25.4016 20.7954 22.3128 23.8841 18.5028 23.8841H14.252C14.823 25.4532 14.4783 27.2799 13.2199 28.5383C12.8492 28.9088 12.4303 29.1999 11.9826 29.4119L13.5597 30.9893C14.9068 32.3369 17.0924 32.3369 18.4395 30.9893L30.9892 18.4401C31.9886 17.4404 32.2467 15.98 31.7633 14.7424Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="23.9998"
          y1="4.5597e-08"
          x2="15.7359"
          y2="31.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="23.9998"
          y1="4.5597e-08"
          x2="15.7359"
          y2="31.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="23.9998"
          y1="4.5597e-08"
          x2="15.7359"
          y2="31.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="23.9998"
          y1="4.5597e-08"
          x2="15.7359"
          y2="31.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
