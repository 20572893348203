import { SVGProps, useId } from 'react';

export const MagicIDLogo = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `${id}-0`;

  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 2C19.0132 2 2 19.0132 2 40C2 60.9868 19.0132 78 40 78C60.9868 78 78 60.9868 78 40C78 19.0132 60.9868 2 40 2ZM37.036 27.1558C35.399 25.5188 35.399 22.8647 37.036 21.2277C38.673 19.5908 41.327 19.5908 42.964 21.2277L50.8681 29.1319C52.5051 30.7688 52.5051 33.4229 50.8681 35.0599L47.9041 38.024L37.036 27.1558ZM26.1678 32.0959L32.0959 26.1678L53.8322 47.9041L47.9041 53.8322L26.1678 32.0959ZM24.1918 34.0719L27.1558 37.036C28.7928 38.673 28.7928 41.327 27.1558 42.964C25.5188 44.601 22.8647 44.601 21.2277 42.964C19.5908 41.327 19.5908 38.673 21.2277 37.036L24.1918 34.0719ZM29.1319 50.8681C27.4949 49.2312 27.4949 46.5771 29.1319 44.9401L32.0959 41.976L42.964 52.8442C44.601 54.4812 44.601 57.1353 42.964 58.7723C41.327 60.4092 38.673 60.4092 37.036 58.7723L29.1319 50.8681ZM55.8082 45.9281L52.8442 42.964C51.2072 41.327 51.2072 38.673 52.8442 37.036C54.4812 35.399 57.1353 35.399 58.7723 37.036C60.4092 38.673 60.4092 41.327 58.7723 42.964L55.8082 45.9281Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="59"
          y1="2"
          x2="47.6397"
          y2="79.1253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
