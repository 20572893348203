import { SVGProps, useId } from 'react';

export const Swap = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 2 }, (_, i) => `${id}-${i}`);
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${gradientIds[0]})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.11 19.7949C13.4772 21.1617 13.4772 23.3778 12.11 24.7447L9.70433 27.1496L3.43938 20.8865L3.43108 20.8948L1.02544 18.4899C0.33936 17.8041 -0.00244976 16.9044 1.32154e-05 16.0054C-0.00175946 15.1074 0.340052 14.2088 1.02545 13.5236L3.43108 11.1187L3.43938 11.127L13.5589 1.01063C14.9068 -0.336878 17.0922 -0.336877 18.4401 1.01063C19.7881 2.35814 19.7881 4.54289 18.4401 5.8904L8.32065 16.0068L12.11 19.7949ZM19.8725 12.1979C18.5052 10.8311 18.5052 8.61498 19.8725 7.24815L22.2781 4.84325L28.5539 11.1171L28.5689 11.1021L30.9746 13.507C32.3418 14.8738 32.3418 17.0899 30.9746 18.4568L18.4411 30.9863C17.0932 32.3338 14.9078 32.3338 13.5598 30.9863C12.2119 29.6388 12.2119 27.4541 13.5598 26.1066L23.6726 15.9969L19.8725 12.1979ZM13.5592 13.559C12.2115 14.9063 12.2115 17.0907 13.5592 18.438C14.907 19.7854 17.0921 19.7854 18.4398 18.438C19.7876 17.0907 19.7876 14.9063 18.4398 13.559C17.0921 12.2116 14.907 12.2116 13.5592 13.559Z"
          fill={`url(#${gradientIds[1]})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientIds[1]}
          x1="24.002"
          y1="-0.00146488"
          x2="17.7072"
          y2="32.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <clipPath id={gradientIds[0]}>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
